<template lang="pug">
v-text-field(
  dense
  flat
  :value="value"
  :disabled="disabled"
  @input="onInput($event)"
  @change="onChange()"
  @focus="toggleInputFocusState"
  @blur="toggleInputFocusState"
  @keypress="onKeypress"
  :placeholder="placeholder"
  data-test="search"
  ref="search_input"
  :class="{'search-disabled': disabled}"
)
  <template v-slot:prepend>
    v-icon(@click="onSearchIconClick" :disabled="disabled") $vuetify.icons.search
  </template>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    placeholder: String,
    disabled: Boolean
  },
  data: () => ({
    isInputFocused: false,
    currentInput: null,
    changedInput: null
  }),
  created() {
    this.currentInput = this.value;
    this.changedInput = this.value;
  },
  methods: {
    toggleInputFocusState() {
      this.isInputFocused = !this.isInputFocused;
      this.$emit('onFocusToggle', this.isInputFocused);
    },
    onInput(event) {
      this.currentInput = event;
      this.$emit('input', event)
    },
    onChange() {
      if (this.currentInput !== this.changedInput) {
        this.changedInput = this.currentInput;
        this.$emit('change', this.changedInput)
      }
    },
    onSearchIconClick() {
      this.$refs.search_input.focus();
      this.onChange()
    },
    onKeypress(event) {
      if(event && event.keyCode === 13) {
        this.$emit('enter-key-pressed', this.currentInput);
      }
      if(event && event.keyCode === 27) {
        console.log(event.keyCode)
        this.$emit('keypress-esc')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-input.v-input--is-focused::v-deep {
  input {
    color: $primary-color;
  }
}

.v-input::v-deep {
  margin: 0;
  padding: 0;

  input {
    font-size: 14px;

    &::placeholder {
      font-size: 18px;
    }
  }

  .v-icon {
    font-size: 20px;
  }

  .v-input__slot {
    margin: 0;

    &:before {
      display: none;
    }
  }

  .v-text-field__details {
    display: none;
  }
}

.search-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
</style>
